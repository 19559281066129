.container {
  background-color: gray;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 0.5rem;
}

.left_bar {
  background-color: #eee;
  /* flex: 1; */
  /* min-width: 150px; */
  width: 18rem;
  height: 100%;
  margin-right: 0.5rem;
  /* overflow: auto; */
}

.right_bar {
  background-color: gray;
  /* flex: 4; */
  width: calc(100vw - 19rem);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* padding: 10px; */
}

.option_and_button {
  display: flex;
  flex-direction: row;
}

.option_bar {
  background-color: rgb(245, 240, 240);
  width: 100%;
  height: 13rem;
  /* min-height: 100px; */
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
  padding: 1rem;
}

.button_bar {
  background-color: thistle;
  width: 250px;
  height: 13rem;
  /* min-height: 100px; */
  margin-bottom: 0.5rem;
  padding: 8px;
}

.main_display {
  height: calc(100vh - 19rem);
  display: flex;
  flex-direction: row;
  /* width: 100%; */
  background-color: gray;
  /* overflow: auto; */
}

.room_bar {
  /* flex: 1; */
  /* min-width: 180px; */
  width: 18rem;
  margin-right: 0.5rem;
  background-color: thistle;
  /* overflow: auto; */
}

.household {
  /* flex: 3; */
  width: calc(100vw - 19rem);
  background-color: thistle;
}
