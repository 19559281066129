.wrapper {
  display: flex;
  height: 440px;
}

.wardsColumn {
  width: 50%;
  /* height: 100%; */
  padding: 0.5rem 1rem;
  background-color: rgb(192, 241, 192);
}

/* .wardsColumn div:first-child h3, .wardsColumn div:first-child  {
  display: flex;
  justify-content: space-between;
} */

.roomsColumn {
  width: 50%;
  background-color: rgb(199, 223, 231);
  padding: 0.5rem 1rem;
}
