.HouseholdForm {
  /* background-color: #f1f1f1; */
  background-color: #e5e5e5;
  padding: 0.5rem;
  border-radius: 4px;
  height: 55vh;
  overflow: auto;
}

.LeftColumn {
  background-color: lightblue;
  padding: 0.5rem;
}

.MiddleColumn {
  background-color: lightsalmon;
  padding: 0.5rem 1rem;
}

.RightColumn {
  background-color: lightgreen;
  padding: 0.5rem 1rem;
  height: 450px;
  overflow: auto;
}

.DataDisplayLabel {
  display: inline-block;
  padding-top: 8px;
  /* margin-bottom: 31px; */
}

.TextBox {
  margin: 3px 8px 9.3px 7px;
}

.DataDisplay {
  display: inline-block;
  background-color: #f1f1f1;
  width: 90%;
  overflow: hidden;
  height: 30px;
  padding-left: 11px;
  padding-top: 4px;
  margin: 4px 8px;
  /* margin-right: 24px; */
}
