.Spin {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.menuHeader {
  background-color: chocolate;
  color: white;
  padding: 8px;
  text-align: center;
  cursor: text;
}
