amplify-greetings {
  --border-color: white;
}

.headerBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0.5rem;
  border-bottom: 1px solid green;
  height: 4.5rem;
}

.logo {
  display: block;
  color: rgba(0, 0, 0, 0.7);
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo:link,
.logo:hover,
.logo:visited {
  color: rgba(0, 0, 0, 0.7);
}

.campName {
  display: block;
  font-size: 1.5rem;
  font-weight: bold;
  color: rgba(0, 0, 150, 0.7);
  padding: 1rem 2rem;
}

.signOutButtonGroup {
  display: flex;
  gap: 2rem;
}

.signOutButtonGroup .Text {
  color: darkred;
  margin-top: 25px;
}

.signOutButtonGroup .Button {
  height: 2.5rem;
  margin-top: 15px;
  margin-right: 15px;
}

.container {
  width: 100%;
  height: calc(100vh - 4.5rem);
  margin: auto;
}
