.container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  align-content: flex-start;
}

.spinContainer {
  display: flex;
  height: 80vh;
  justify-content: center;
  align-items: center;
}
